<template>
  <div>
    <div style="background:#3e06a5">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>
    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <router-link to="/components/web/joinus/case01">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>{{ $t('Nav.zhaopin') }}</div>
              </div>
            </li>
          </router-link>
          <router-link to="/components/web/joinus/case02">
            <li class="tabStyleLi">
              <div class="tabStyle" style="background:#3246F4"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>{{ $t('Nav.peixunyufazhan') }}</div>
              </div>
            </li>
          </router-link>
          <router-link to="/components/web/joinus/case03">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.shenghuoyufuli') }}</div>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133"
            >{{ $t('Nav.home') }}
          </span></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/joinus/index' }"
          ><span style="color:#303133">{{
            $t('Nav.abus')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">{{
            $t('Nav.peixunyufazhan')
          }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div style="width:1200px; margin:0 auto;height:400px;">
      <div
        style="margin-top:50px;margin-bottom:30px; font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
      >
        {{ $t('Nav.peixunyufazhan') }}
      </div>
      <div>
        <div style="float:right;width:50%;">
          <el-image
            :src="corporateVision1"
            fit=""
            style="width:500px;height:270px;"
          ></el-image>
        </div>
        <div
          style="float:left; width:30%; text-align:left; padding: 50px 0 0 200px"
        >
          <div style="margin-bottom:10px">
            <span style="margin-right:10px;">○</span>{{ $t('Nav.qxfz01') }}
          </div>
          <div style="margin-bottom:10px">
            <span style="margin-right:10px;">○</span>{{ $t('Nav.qxfz02') }}
          </div>
          <div style="margin-bottom:10px">
            <span style="margin-right:10px;">○</span>{{ $t('Nav.qxfz03') }}
          </div>
          <div style="margin-bottom:10px">
            <span style="margin-right:10px;">○</span>{{ $t('Nav.qxfz04') }}
          </div>
          <div style="margin-bottom:10px">
            <span style="margin-right:10px;">○</span>{{ $t('Nav.qxfz05') }}
          </div>
          <div style="margin-bottom:10px">
            <span style="margin-right:10px;">○</span>{{ $t('Nav.qxfz06') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'case01',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/55.jpg'),
      corporateVision1: require('../../../assets/joinus/3.png'),
      tabPosition: 'left',
    }
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 170px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
.el-tabs__item {
  font-size: 20px;
}
.el-tabs__item:hover {
  color: #3246f4;
}
.el-tabs__item.is-active {
  color: #3246f4;
}
</style>
